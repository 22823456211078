// Packages
import React from 'react';
import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';

// Types
import type { SiteMetadata, Location } from '~types/Gatsby';
import { Blog } from '~types/Blog';

// Comppnents
import Layout from '~components/layouts/Default';
import PageHeader from '~components/sections/PageHeader';
import Container from '~components/common/Container';
import H1 from '~components/typography/H1';
import H2 from '~components/typography/H2';
import P from '~components/typography/P';
import SubTitle from '~components/typography/Subtitle';
import TestmonialSingle from '~components/sections/Testmonial';
import Grid from '~components/common/Grid';
import Image from '~components/common/Image';
import { CheckCircleIcon } from '@heroicons/react/outline';
import H3 from '~components/typography/H3';
import BlogCard from '~components/common/BlogCard';
import FaqItem from '~components/common/FaqItem';
import { LpLandingpageData } from '~types/LpLandingpage';
import NewsletterForm from '~components/forms/newsletter/NewsletterForm';

interface DataProps {
  site: {
    siteMetadata: SiteMetadata;
  };
  landingpageNewsletter: LpLandingpageData;
  allBlog: {
    nodes: Blog[];
  };
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
  const landingpage = data.landingpageNewsletter;

  return (
    <Layout location={location} title={landingpage.seoTitle} desc={landingpage.seoDescription} minimalFooter={true}>
      <PageHeader image={landingpage.remoteHeaderImage} classNameImage="mx-auto" divider>
        <span className="mb-3 inline-flex items-center rounded-full bg-brand-gray p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base">
          <span className="rounded-full bg-brand-green px-3 py-0.5 text-sm font-semibold leading-5 text-brand-gray">{landingpage.headerTag}</span>
          <span className="ml-4 text-sm">Jetzt kostenfrei anmelden</span>
        </span>
        <H1 className={`${landingpage?.headerSubheadline ? 'mb-2' : 'mb-4'} text-brand-gray`}>{landingpage.headerHeadline}</H1>
        {landingpage?.headerSubheadline && <H2 className="mb-4">{landingpage.headerSubheadline}</H2>}
        <div className="prose">{parse(landingpage.headerText)}</div>
        <div className="mt-10 sm:mt-12">
          <NewsletterForm listId={landingpage.listId} dataProtecionText={landingpage.dataProtecionText} />
        </div>
      </PageHeader>
      <Container>
        <Grid cols={2}>
          <div>
            <SubTitle className="mb-2">{landingpage.contentTag}</SubTitle>
            <H2 className={`${landingpage?.contentSubheadline ? 'mb-2' : 'mb-4'} text-brand-gray`}>{landingpage.contentHeadline}</H2>
            {landingpage?.contentSubheadline && <H3 className="mb-4">{landingpage.contentSubheadline}</H3>}
            <div className="prose">{parse(landingpage.contentText)}</div>
          </div>
          <Image imageData={landingpage.remoteContentImage} alt={landingpage.contentHeadline + ' Bild'} />
        </Grid>
      </Container>
      <Container>
        <div className="mx-auto max-w-2xl text-center">
          <SubTitle className="mb-2">{landingpage.featureTag}</SubTitle>
          <H2 className={`${landingpage?.featureSubheadline ? 'mb-2' : 'mb-4'} text-brand-gray`}>{landingpage.featureHeadline}</H2>
          {landingpage?.featureSubheadline && <H3 className="mb-4">{landingpage.featureSubheadline}</H3>}
          <P className="mb-3">{landingpage.featureText}</P>
        </div>
        <div className="mt-12">
          <Grid cols={3}>
            {landingpage.featureItems.map((feature) => (
              <div className="pt-6 text-center" key={feature.value.Headline}>
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-6">
                    <div className="mb-8">
                      <span className="inline-flex items-center justify-center rounded-md bg-brand-green p-3 shadow-lg">
                        <CheckCircleIcon className="h-6 w-6" />
                      </span>
                    </div>
                    <H3 as="p" className="mb-5">
                      {feature.value.Headline}
                    </H3>
                    <P>{feature.value.Text}</P>
                  </div>
                </div>
              </div>
            ))}
          </Grid>
        </div>
      </Container>
      <TestmonialSingle imageData={landingpage.remoteTestmonialImage} quote={landingpage.testmonialText} alt="Kundenstimme" author={landingpage.testmonialAuthor} />
      <Container>
        <div className="mx-auto max-w-2xl text-center">
          <SubTitle className="mb-2">Ich will mehr erfahren</SubTitle>
          <H2 className="mb-4">Wertvolle Ressourcen</H2>
          <P className="mb-3">
            In unserem Blog haben wir einige Artikel zum Thema mentaler Gesundheit geschrieben, in dem du alle wesentlichen Informationen bekommst, die dich motivieren zu starten.{' '}
            <Link to="/blog/" className="underline">
              Zu allen Blogbeiträgen
            </Link>
            .
          </P>
        </div>
        <div className="mt-12">
          <Grid cols={3}>
            {data.allBlog.nodes.map((entry) => {
              return <BlogCard title={entry.titel} image={entry.remoteTitelbild} link={`/blog/${entry.slug_slug}/`} text={entry.einleitung} date={entry.datum} key={entry.id} fullText={entry.text} />;
            })}
          </Grid>
        </div>
      </Container>
      {landingpage.faq && landingpage.faq.length > 0 && (
        <Container>
          <Grid cols={2}>
            <div>
              <span className="mb-2 font-display text-xl font-bold uppercase text-brand-green">FAQ</span>
              <H2 className="mb-4 text-brand-gray">Du hast noch Fragen?</H2>
              <dl className="space-y-3">
                {landingpage.faq.map((faq) => {
                  return <FaqItem title={faq.value.Frage} text={<p>{faq.value.Antwort}</p>} key={faq.value.Antwort} />;
                })}
              </dl>
            </div>
            {landingpage.remoteFaqImage && <Image imageData={landingpage.remoteFaqImage} alt="" />}
          </Grid>
        </Container>
      )}

      <Container>
        <div className="mx-auto max-w-2xl text-center">
          <span className="mb-3 inline-flex items-center rounded-full bg-brand-gray p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base">
            <span className="rounded-full bg-brand-green px-3 py-0.5 text-sm font-semibold leading-5 text-brand-gray">{landingpage.ctaTag}</span>
            <span className="ml-4 text-sm">Jetzt kostenfrei anmelden</span>
          </span>
          <H1 className="mb-5">{landingpage.ctaTag}</H1>
          <P className="text-base sm:text-xl lg:text-lg xl:text-xl">{landingpage.ctaText}</P>
          <div className="mt-10 sm:mt-12">
            <NewsletterForm listId={landingpage.listId} dataProtecionText={landingpage.dataProtecionText} />
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  {
    landingpageNewsletter {
      seoTitle
      seoDescription
      dataProtecionText
      headerText
      testmonialText
      testmonialAuthor
      remoteTestmonialImage {
        childImageSharp {
          gatsbyImageData
        }
      }
      listId
      headerTag
      remoteHeaderImage {
        childImageSharp {
          gatsbyImageData
        }
      }
      headerHeadline
      headerSubheadline
      featureText
      featureTag
      featureItems {
        value {
          Headline
          Text
        }
      }
      featureHeadline
      featureSubheadline
      faq {
        value {
          Antwort
          Frage
        }
      }
      remoteFaqImage {
        childImageSharp {
          gatsbyImageData
        }
      }
      ctaText
      ctaTag
      ctaHeadline
      contentText
      contentTag
      remoteContentImage {
        childImageSharp {
          gatsbyImageData
        }
      }
      contentHeadline
      contentSubheadline
    }

    allBlog(sort: { fields: datum, order: DESC }, limit: 6) {
      nodes {
        remoteTitelbild {
          childImageSharp {
            gatsbyImageData(quality: 80, placeholder: BLURRED)
          }
        }
        datum
        einleitung
        slug_slug
        text
        titel
        id
      }
    }
  }
`;
